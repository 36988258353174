import React from "react";
import "./Features.scss";

import geo from "../../img/geo.svg";
import speed from "../../img/speed.svg";
import multi from "../../img/multi.svg";

function Features() {
  return (
    <section id="advantages" className="features">
      <div className="container">
        <div className="features__block">
          <div className="features__block-item">
            <img src={geo} alt="География" />
            <h3>География</h3>
            <p>Перевод между картами банков РФ</p>
          </div>
          <div className="features__block-item">
            <img src={speed} alt="Скорость" />
            <h3>Скорость</h3>
            <p>Зачисление денег на счет за несколько секунд</p>
          </div>
          <div className="features__block-item">
            <img src={multi} alt="Масштабность" />
            <h3>Безопасность</h3>
            <p>Используем технологию 3DSecure</p>
          </div>
          {/* <img className="wave" src={wave} alt="волна" /> */}
        </div>
      </div>
    </section>
  );
}

export default Features;
