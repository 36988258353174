"use client";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Box,
  Heading,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";

export default function Faq() {
  return (
    <Flex
      id="quest"
      align={"center"}
      justify={"center"}
      direction={"column"}
      mt={10}
    >
      <Heading className="title__line" fontWeight={600}>
        Вопросы и ответы
      </Heading>
      <Flex
        w={{ base: "xs", lg: "xl" }}
        bg={"white"}
        p={10}
        rounded={"xl"}
        shadow={"base"}
        mt={10}
      >
        <Accordion width={"full"}>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Как это работает?
                </Box>
                <ChevronDownIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Для перевода нужно заполнить номер, срок действия, CVV/CVC код
              карты отправителя, а также номер карты получателя, сумму и вашу
              почту для получения уведомления о статусе перевода.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  С каких карт можно совершать переводы?
                </Box>
                <ChevronDownIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Переводы можно совершать с банковских карт платежной системы МИР
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Гарантирована ли безопасность данных моей платежной карты?
                </Box>
                <ChevronDownIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Система переводов работает по протоколу безопасности TLS, который
              гарантирует защиту данных вводимых клиентом, в том числе и данные
              карты.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Что такое CVV2,CVC2?
                </Box>
                <ChevronDownIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              3-х значный код, который расположен на обратной стороне карты,
              необходимый для дополнительной аутентификации владельца карты. При
              наличии данного кода карта пригодна для оплаты в интернете.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Какие лимиты для переводов?
                </Box>
                <ChevronDownIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Суточный лимит на переводы - 100 тысяч рублей. Единоразовый
              перевод не должен превышать 15 тысяч рублей.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </Flex>
  );
}
