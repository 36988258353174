import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { motion } from "framer-motion";

import { useForm } from "react-hook-form";
const Feedback = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const {
    register,

    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    onClose();
    reset();
    toast({
      status: "success",
      title: "Сообщение отправлено!",
    });
  };

  return (
    <Box
      onClick={onOpen}
      cursor="pointer"
      position="fixed"
      display={{ base: "none", lg: "block" }}
      bottom={{ base: 4, lg: 10 }}
      right={{ base: 2, lg: 20 }}
    >
      <motion.div
        animate={{
          scale: [1, 1.1, 1.1, 1, 1],
        }}
        transition={{
          repeat: Infinity,
          duration: 2,
        }}
      >
        <Box
          p={2}
          border="1px"
          borderColor={useColorModeValue("gray.200", "gray.600")}
          rounded="full"
          bg={useColorModeValue("white", "gray.700")}
          shadow="md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M20 4C22.4162 4.0014 24.8008 4.55001 26.9746 5.60467C29.1485 6.65934 31.0553 8.19266 32.5519 10.0896C34.0486 11.9865 35.0961 14.1977 35.616 16.5574C36.1359 18.917 36.1146 21.3637 35.5537 23.7139C34.9929 26.0642 33.907 28.2568 32.3776 30.1274C30.8483 31.998 28.9151 33.4979 26.7232 34.5146C24.5313 35.5313 22.1375 36.0384 19.7217 35.9977C17.3058 35.9571 14.9305 35.3698 12.774 34.28L12.532 34.15L5.242 35.97C5.1017 36.0053 4.95542 36.0097 4.81325 35.9829C4.67108 35.9562 4.5364 35.8989 4.41852 35.8151C4.30063 35.7312 4.20234 35.6228 4.13043 35.4973C4.05852 35.3717 4.0147 35.2321 4.002 35.088V34.924L4.03 34.758L5.85 27.47L5.724 27.23C4.80877 25.4283 4.24538 23.4687 4.064 21.456L4.014 20.692L4 20C4 15.7565 5.68571 11.6869 8.68629 8.68629C11.6869 5.68571 15.7565 4 20 4ZM21 22H15L14.82 22.016C14.5894 22.0577 14.3809 22.1791 14.2307 22.359C14.0805 22.5388 13.9982 22.7657 13.9982 23C13.9982 23.2343 14.0805 23.4612 14.2307 23.641C14.3809 23.8209 14.5894 23.9423 14.82 23.984L15 24H21L21.18 23.984C21.4106 23.9423 21.6192 23.8209 21.7694 23.641C21.9196 23.4612 22.0018 23.2343 22.0018 23C22.0018 22.7657 21.9196 22.5388 21.7694 22.359C21.6192 22.1791 21.4106 22.0577 21.18 22.016L21 22ZM25 16H15L14.82 16.016C14.5894 16.0577 14.3809 16.1791 14.2307 16.359C14.0805 16.5388 13.9982 16.7657 13.9982 17C13.9982 17.2343 14.0805 17.4612 14.2307 17.641C14.3809 17.8209 14.5894 17.9423 14.82 17.984L15 18H25L25.18 17.984C25.4106 17.9423 25.6192 17.8209 25.7694 17.641C25.9196 17.4612 26.0018 17.2343 26.0018 17C26.0018 16.7657 25.9196 16.5388 25.7694 16.359C25.6192 16.1791 25.4106 16.0577 25.18 16.016L25 16Z"
              fill="#52d3aa"
            />
          </svg>
        </Box>
      </motion.div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "xs", lg: "md" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent rounded={"xl"}>
            <ModalHeader rounded={"xl"} textAlign="center">
              Обратная связь
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack>
                <Stack
                  alignItems="start"
                  direction={{ base: "column", lg: "row" }}
                >
                  <FormControl isInvalid={Boolean(errors.feedback_contact)}>
                    <FormLabel>Контактное лицо</FormLabel>
                    <Input
                      type="text"
                      {...register("feedback_contact", {
                        required: "Укажите имя",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.feedback_contact && (
                        <>{errors.feedback_contact.message}</>
                      )}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={Boolean(errors.feedback_email)}>
                    <FormLabel>Почта</FormLabel>
                    <Input
                      type="email"
                      {...register("feedback_email", {
                        required: "Укажите почту",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.feedback_email && (
                        <>{errors.feedback_email.message}</>
                      )}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>

                <FormControl isInvalid={Boolean(errors.feedback_message)}>
                  <FormLabel>Сообщение</FormLabel>
                  <Textarea
                    autoComplete="new-password"
                    resize="none"
                    rows={6}
                    {...register("feedback_message", {
                      required: "Укажите сообщение",
                    })}
                  />

                  <FormErrorMessage>
                    {errors.feedback_message && (
                      <>{errors.feedback_message.message}</>
                    )}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter display="flex" justifyContent="center">
              <Button type="submit" mr={3}>
                Отправить
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Box>
  );
};

export default Feedback;
