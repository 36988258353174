import React from "react";
import "./Contacts.scss";

function Contacts() {
  return (
    <section id="contact" className="contacts">
      <div className="container">
        <div className="contacts__block">
          <div className="contacts__block-header">
            <h2 className="text-center title__line">Контакты и реквизиты</h2>
          </div>
          <div className="contacts__block-items">
            <div className="contacts__block-items-item">
              <h3>Контакты</h3>
              <p>
                Кыргызская Республика, г.Бишкек, <br /> Свердловский район, ул.
                Ибрагимова, <br /> д.115, Бизнес центр «Асыл-Таш»
              </p>
              <p>+996 501 833 460</p>
              <p>info@asiapayment.kg</p>
            </div>
            <div className="contacts__block-items-item">
              <h3>Реквизиты</h3>
              <p>LLC «Asia Payment Solution»</p>
              <p>ИНН 9909651006</p>
              <p>ОГРН 201378-3301-ООО</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contacts;
