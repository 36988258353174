import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Center,
  Spinner,
  Box,
  Heading,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CloseIcon, CheckCircleIcon } from "@chakra-ui/icons";

const TransferModal = () => {
  const [transfer, setTransfer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { onClose } = useDisclosure();
  const navigate = useNavigate();
  function onCloseModal() {
    navigate("/");
    onClose();
  }

  const [searchParams] = useSearchParams();

  const orderid = searchParams.get("orderid");

  async function fetchTransferInfo() {
    setIsLoading(true);

    try {
      const res = await fetch(
        `https://cardtocard.kg/api/transfer/status?id=${orderid}`
      );

      if (res.ok) {
        setIsLoading(false);
        const transfer = await res.json();
        console.log(transfer);
        setTransfer(transfer);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (orderid) {
      fetchTransferInfo(orderid);
    }
  }, [orderid]);

  return (
    <Modal
      isOpen={orderid}
      onClose={() => onCloseModal()}
      isCentered
      size={{ base: "full", lg: "xl" }}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader textAlign={"center"} roundedTop={"xl"}>
          {isLoading && "Обрабатываем перевод..."}
        </ModalHeader> */}
        <ModalCloseButton
          _focus={{
            boxShadow: "none",
            borderColor: "inherit",
          }}
        />
        <ModalBody>
          <Center h={"lg"}>
            {isLoading && <Spinner size={"xl"} colorScheme="green" />}
            {transfer &&
              (transfer.status === "approved" ||
              transfer.status === "processing" ? (
                <Box textAlign="center" py={10} px={6}>
                  <CheckCircleIcon boxSize={"50px"} color={"green.500"} />
                  <Heading as="h2" size="lg" mt={6} mb={2}>
                    Перевод №{transfer.sale.paynetOrderId} принят в обработку!
                  </Heading>
                  <Text color={"gray.500"}>
                    Деньги поступят на указанный счет не позднее 24 часов.
                    Обычно это происходит почти мгновенно!
                  </Text>
                </Box>
              ) : (
                <Box textAlign="center" py={10} px={6}>
                  <Box display="inline-block">
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      bg={"red.500"}
                      rounded={"50px"}
                      w={"55px"}
                      h={"55px"}
                      textAlign="center"
                    >
                      <CloseIcon boxSize={"20px"} color={"white"} />
                    </Flex>
                  </Box>
                  <Heading as="h2" size="lg" mt={6} mb={2}>
                    Произошла ошибка!
                  </Heading>
                  <Text color={"gray.500"}>
                    {transfer.sale.status !== "approved" &&
                      " Не удалось произвести списание средств с указанной карты. Попробуйте другую карту списания!"}
                    {transfer.withdrawal.status !== "approved" &&
                      transfer.sale.status === "approved" &&
                      "Не удалось произвести зачисление средств на указанную карту. Попробуйте другую карту зачисления!"}
                  </Text>
                </Box>
              ))}
          </Center>
        </ModalBody>

        {/* <ModalFooter>
           
          </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};
export default TransferModal;
