import "./App.scss";
import {
  Header,
  Features,
  Guide,
  Contacts,
  Footer,
  Cardform,
  Feedback,
  Tarifs,
  Faq,
  TransferModal,
} from "./components";

function App() {
  return (
    <div className="App">
      <Header />
      <TransferModal />
      <Cardform />
      <Guide />
      <Features />
      <Tarifs />
      <Faq />
      <Contacts />
      <Footer />
      <Feedback />
    </div>
  );
}

export default App;
