import {
  Flex,
  Stack,
  HStack,
  Box,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  Text,
  Heading,
  FormErrorMessage,
  Link,
  Button,
  InputRightElement,
  useToast,
  Spinner,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalBody,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { ChevronRightIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useForm, Controller } from "react-hook-form";

import style from "./transfer.module.scss";
import { useState } from "react";

const Cardform = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { onClose } = useDisclosure();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const amount = watch("amount");

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const res = await fetch("https://cardtocard.kg/api/transfer", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json",
        },
      });

      if (res.ok) {
        const resData = await res.json();
        console.log(resData);
        if (resData.status === "success") {
          window.location.href = resData.url;
        }
      } else {
        setIsLoading(false);
        toast({
          status: "error",
          title: "Упс, что-то пошло не так, попробуйте попытку позднее!",
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className={style.transfer}>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            w={"full"}
            h={"full"}
            direction={"column"}
            bg={"white"}
            border={{ base: "none", lg: "1px" }}
            borderColor={{ lg: "gray.200" }}
            rounded={"2xl"}
            shadow={{ lg: "base" }}
            py={{ base: 0, lg: 10 }}
            px={{ base: 4, lg: 28 }}
          >
            <Stack align={"center"}>
              <Heading
                fontSize={{ base: "xl", lg: "3xl" }}
                textAlign={"center"}
                mb={4}
                // opacity={0.8}
              >
                Перевод с карты на карту
              </Heading>
              <Text
                fontSize={{ base: "sm", lg: "md" }}
                color={"gray.600"}
                textAlign={"center"}
                display={{ base: "none", lg: "block" }}
              >
                Мгновенный перевод денег между картами МИР любых банков РФ
              </Text>
            </Stack>
            <Flex
              w="full"
              direction={{ base: "column", lg: "row" }}
              gap={{ base: 0, lg: 6 }}
              justifyContent={{ base: "center", lg: "space-between" }}
              alignItems={"center"}
              mt={{ base: 0, lg: 4 }}
            >
              <Flex direction={"column"} w={"full"}>
                <Heading
                  fontSize={{ base: "xl", lg: "3xl" }}
                  mb={6}
                  display={{ base: "none", lg: "block" }}
                  // opacity={0.7}
                >
                  С карты
                </Heading>
                <Flex
                  align={"center"}
                  justify={"center"}
                  bg="gray.50"
                  shadow="base"
                  rounded={"2xl"}
                  direction={"column"}
                  justifyContent={"center"}
                  h={{ base: "auto", lg: 280 }}
                  w={{ base: "full", lg: "full" }}
                >
                  <Stack
                    spacing={8}
                    mx={"auto"}
                    w={"full"}
                    p={{ base: 0, lg: 4 }}
                  >
                    <Box rounded={"lg"} p={8}>
                      <Stack spacing={4}>
                        <FormControl
                          isInvalid={Boolean(errors.creditCardNumber)}
                        >
                          {/* <FormLabel>Номер карты отправителя</FormLabel> */}
                          <Controller
                            control={control}
                            name="creditCardNumber"
                            rules={{
                              required: true,
                              validate: (value) =>
                                value.match(/\d/g).length < 19 ||
                                "Неправильный номер карты",
                            }}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <InputGroup>
                                <PatternFormat
                                  name="creditCardNumber"
                                  autoComplete="new-password"
                                  // allowEmptyFormatting
                                  placeholder="0000 0000 0000 0000"
                                  size={{ base: "md", lg: "lg" }}
                                  customInput={Input}
                                  format="#### #### #### ####"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                />
                                <InputRightElement h={"full"} p={2}>
                                  <MirIcon />
                                </InputRightElement>
                              </InputGroup>
                            )}
                          />
                          <FormErrorMessage>
                            {errors.creditCardNumber && (
                              <>{errors.creditCardNumber.message}</>
                            )}
                          </FormErrorMessage>
                        </FormControl>
                        <HStack justifyContent={"space-between"}>
                          <HStack>
                            <FormControl
                              isInvalid={Boolean(errors.expireMonth)}
                            >
                              {/* <FormLabel>Месяц</FormLabel> */}
                              <Controller
                                control={control}
                                name="expireMonth"
                                rules={{
                                  required: true,
                                  validate: (value) => value > 12 || true,
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                }) => (
                                  <PatternFormat
                                    name="expireMonth"
                                    autoComplete="new-password"
                                    // allowEmptyFormatting
                                    placeholder="00"
                                    maxW={14}
                                    max={12}
                                    size={{ base: "md", lg: "lg" }}
                                    customInput={Input}
                                    format="##"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                  />
                                )}
                              />
                              <FormErrorMessage>
                                {errors.expireMonth && (
                                  <>{errors.expireMonth.message}</>
                                )}
                              </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={Boolean(errors.expireYear)}>
                              {/* <FormLabel>Год</FormLabel> */}
                              <Controller
                                control={control}
                                name="expireYear"
                                rules={{
                                  required: true,
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                }) => (
                                  <PatternFormat
                                    name="expireYear"
                                    autoComplete="new-password"
                                    // allowEmptyFormatting
                                    placeholder="00"
                                    maxW={14}
                                    size={{ base: "md", lg: "lg" }}
                                    customInput={Input}
                                    format="##"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                  />
                                )}
                              />
                              <FormErrorMessage>
                                {errors.expireYear && (
                                  <>{errors.expireYear.message}</>
                                )}
                              </FormErrorMessage>
                            </FormControl>
                          </HStack>
                          <HStack>
                            <FormControl isInvalid={Boolean(errors.cvv)}>
                              {/* <FormLabel>CVC2/CVV2</FormLabel> */}

                              <Controller
                                control={control}
                                name="cvv"
                                rules={{
                                  required: true,
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                }) => (
                                  <PatternFormat
                                    name="cvv"
                                    type="password"
                                    autoComplete="new-password"
                                    size={{ base: "md", lg: "lg" }}
                                    w={20}
                                    // allowEmptyFormatting
                                    placeholder="CVV"
                                    customInput={Input}
                                    format="###"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                  />
                                )}
                              />

                              <FormErrorMessage>
                                {errors.cvv && <>{errors.cvv.message}</>}
                              </FormErrorMessage>
                            </FormControl>
                          </HStack>
                        </HStack>
                        <FormControl
                          isInvalid={Boolean(errors.cardPrintedName)}
                        >
                          {/* <FormLabel>Имя на карте</FormLabel> */}

                          <Input
                            {...register("cardPrintedName", { required: true })}
                            type="text"
                            name="cardPrintedName"
                            autoComplete="new-password"
                            placeholder="IVAN IVANOV"
                            size={{ base: "md", lg: "lg" }}
                          />

                          <FormErrorMessage>
                            {errors.cardPrintedName && (
                              <>{errors.cardPrintedName.message}</>
                            )}
                          </FormErrorMessage>
                        </FormControl>
                      </Stack>
                    </Box>
                  </Stack>
                </Flex>
              </Flex>
              <ChevronRightIcon
                display={{ base: "none", lg: "block" }}
                h={50}
                w={50}
                color={"gray.300"}
                mt={{ base: 0, lg: 10 }}
              />
              <ChevronDownIcon
                display={{ base: "block", lg: "none" }}
                h={50}
                w={50}
                color={"gray.300"}
                mt={{ base: 0, lg: 10 }}
              />
              <Flex direction={"column"} w={"full"}>
                <Heading
                  fontSize={{ base: "xl", lg: "3xl" }}
                  mb={6}
                  display={{ base: "none", lg: "block" }}
                  // opacity={0.7}
                >
                  На карту
                </Heading>
                <Flex
                  align={"center"}
                  justify={"center"}
                  bg="gray.50"
                  shadow="base"
                  rounded={"2xl"}
                  h={{ base: "auto", lg: 280 }}
                  w={{ base: "full", lg: "full" }}
                  direction={"column"}
                  justifyContent={"start"}
                >
                  <Stack
                    spacing={8}
                    mx={"auto"}
                    maxW={"lg"}
                    w={"full"}
                    p={{ base: 0, lg: 4 }}
                  >
                    <Box rounded={"lg"} p={8}>
                      <Stack spacing={4}>
                        <FormControl isInvalid={Boolean(errors.cardNumberTo)}>
                          {/* <FormLabel>Номер карты получателя</FormLabel> */}
                          <Controller
                            control={control}
                            name="cardNumberTo"
                            rules={{
                              required: true,
                              validate: (value) =>
                                value.match(/\d/g).length > 10 ||
                                "Неправильный номер",
                            }}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <InputGroup>
                                <PatternFormat
                                  name="cardNumberTo"
                                  autoComplete="new-password"
                                  // allowEmptyFormatting
                                  placeholder="0000 0000 0000 0000"
                                  size={{ base: "md", lg: "lg" }}
                                  customInput={Input}
                                  format="#### #### #### ####"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                />
                                <InputRightElement h={"full"} p={2}>
                                  <MirIcon />
                                </InputRightElement>
                              </InputGroup>
                            )}
                          />
                          <FormErrorMessage>
                            {errors.cardNumberTo && (
                              <>{errors.cardNumberTo.message}</>
                            )}
                          </FormErrorMessage>
                        </FormControl>
                      </Stack>
                    </Box>
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
            <Stack mt={4} direction={{ base: "row", lg: "row" }}>
              <FormControl isInvalid={Boolean(errors.amount)}>
                <FormLabel>Сумма:</FormLabel>
                <Controller
                  control={control}
                  name="amount"
                  rules={{
                    required: "Укажите сумму",
                    max: 15000,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <NumericFormat
                      name="amount"
                      autoComplete="new-password"
                      placeholder="0 ₽"
                      size={{ base: "md", lg: "lg" }}
                      customInput={Input}
                      max={15000}
                      maxLength={5}
                      // suffix={" ₽"}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.amount && <>{errors.amount.message}</>}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.email)}>
                <FormLabel>Почта:</FormLabel>
                <Input
                  {...register("email", { required: true })}
                  type="email"
                  name="email"
                  autoComplete="new-password"
                  placeholder="ivanov@mail.ru"
                  size={{ base: "md", lg: "lg" }}
                />

                <FormErrorMessage>
                  {errors.email && <>{errors.email.message}</>}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack mt={4}>
              <HStack>
                <Heading size={{ base: "sm", lg: "md" }}>Комиссия: </Heading>
                <Heading fontWeight={400} size={"md"}>
                  {amount ? Math.floor((amount / 100) * 5) : 0} ₽
                </Heading>
                <Box mb={2}>
                  <Popover>
                    <PopoverTrigger>
                      <InfoOutlineIcon opacity={0.5} />
                    </PopoverTrigger>
                    <PopoverContent
                      _focus={{
                        boxShadow: "none",
                        borderColor: "inherit",
                      }}
                    >
                      <PopoverCloseButton />

                      <PopoverBody>
                        Комиссия сервиса составляет 5% от суммы перевода.
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
              </HStack>
              <HStack>
                <Heading size={{ base: "sm", lg: "md" }}>Итого: </Heading>
                <Heading fontWeight={400} size={"md"}>
                  {amount ? Math.floor(amount - (amount / 100) * 5) : 0} ₽
                </Heading>
              </HStack>
            </Stack>
            {/* <FormControl isInvalid={Boolean(errors.approval)}>
              <Checkbox
                {...register("approval", {
                  required: "Необходимо согласие",
                })}
              >
               
              </Checkbox>
              <FormErrorMessage>
                {errors.approval && <>{errors.approval.message}</>}
              </FormErrorMessage>
            </FormControl> */}
            <Flex justifyContent={"center"}>
              <Button
                width={{ base: "full", lg: "auto" }}
                type="submit"
                size={"lg"}
                bg={"#52d3aa"}
                fontWeight={300}
              >
                Перевести
              </Button>
            </Flex>
            <Text
              textAlign={{ lg: "center" }}
              fontSize={"sm"}
              mt={2}
              opacity={0.5}
              mb={0}
            >
              Нажимая кнопку "Перевести", вы соглашаетесь с{" "}
              <Link color={"blue.300"} target="_blank" href="/docs/oferta.pdf">
                условиями предоставления услуг.
              </Link>
            </Text>
          </Flex>
          <Modal onClose={onClose} size={"full"} isOpen={isLoading}>
            <ModalOverlay />
            <ModalContent>
              <ModalBody
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {true && <Spinner size={"xl"} />}
              </ModalBody>
            </ModalContent>
          </Modal>
        </form>
      </div>
    </section>
  );
};

export default Cardform;

const MirIcon = () => {
  return (
    <svg version="1.1" id="Layer_1" viewBox="0 0 780 500">
      <path
        className="st0"
        fill="none"
        stroke="#393939"
        strokeOpacity={0.4}
        strokeWidth={30}
        d="M55,14.5h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H55c-22.1,0-40-17.9-40-40v-391
   C15,32.4,32.9,14.5,55,14.5z"
      />
      <g id="Page-1">
        <g id="Artboard" transform="translate(-91.000000, -154.000000)">
          <g id="Group" transform="translate(91.000000, 154.000000)">
            <path
              id="Combined-Shape"
              className="st1"
              fillOpacity={0.4}
              d="M544.1,240.5v108h60v-64h68c28.6-0.2,52.9-18.5,62.1-44H544.1z"
            />
            <path
              id="Combined-Shape_1_"
              className="st1"
              fillOpacity={0.4}
              d="M536.1,151.5c3.5,44.1,45.3,79,96.3,79c0.2,0,104.3,0,104.3,0
               c0.8-4,1.2-8.2,1.2-12.5c0-36.6-29.5-66.2-66-66.5L536.1,151.5z"
            />
            <path
              id="Combined-Shape_2_"
              className="st1"
              fillOpacity={0.4}
              d="M447.3,229.4l0-0.1L447.3,229.4c0.7-1.2,1.8-1.9,3.2-1.9c2,0,3.5,1.6,3.6,3.5l0,0
               v116.5h60v-196h-60c-7.6,0.3-16.2,5.8-19.4,12.7L387,266.6c-0.1,0.4-0.3,0.8-0.5,1.2l0,0l0,0c-0.7,1-1.9,1.7-3.3,1.7
               c-2.2,0-4-1.8-4-4v-114h-60v196h60v0c7.5-0.4,15.9-5.9,19.1-12.7l49-105.1C447.2,229.6,447.3,229.5,447.3,229.4L447.3,229.4z"
            />
            <path
              id="Combined-Shape_3_"
              className="st1"
              fill="#393939"
              fillOpacity={0.5}
              d="M223.3,232.8l-35.1,114.7H145L110,232.7c-0.3-1.8-1.9-3.2-3.9-3.2
               c-2.2,0-3.9,1.8-3.9,3.9c0,0,0,0,0,0l0,114h-60v-196h51.5H109c11,0,22.6,8.6,25.8,19.1l29.2,95.5c1.5,4.8,3.8,4.7,5.3,0
               l29.2-95.5c3.2-10.6,14.8-19.1,25.8-19.1h15.3h51.5v196h-60v-114c0,0,0,0,0-0.1c0-2.2-1.8-3.9-3.9-3.9
               C225.2,229.5,223.6,230.9,223.3,232.8L223.3,232.8z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
