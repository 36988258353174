import React from "react";
import { Nav } from "react-bootstrap";
import { Heading, Flex } from "@chakra-ui/react";
import "./Footer.scss";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="footer__block">
          <div className="footer__block-logo">
            <Heading size={"lg"}>
              <Flex alignItems={"center"}>
                CARD
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="22"
                  viewBox="0 0 34 22"
                  fill="none"
                >
                  <path
                    d="M33.2701 0.95166H12.2044H0.204407L19.5 6L0.204407 21.9517H33.2701L14 17L33.2701 0.95166Z"
                    fill="#51DEB1"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 17L33.2701 0.95166H12.2044H0.204407L19.5 6L0.204407 21.9517H33.2701L14 17Z"
                    fill="#51DEB1"
                  />
                </svg>
                CARD
              </Flex>
            </Heading>
          </div>
          <div className="footer__block-nav">
            <ul>
              <Nav.Link href="#how">Как это работает?</Nav.Link>
              <Nav.Link href="#advantages">Преимущества</Nav.Link>
              <Nav.Link href="#quest">Вопросы и ответы</Nav.Link>
            </ul>
          </div>
          <div className="footer__block-docs">
            <ul>
              <Nav.Link href="/docs/oferta.pdf">Оферта</Nav.Link>
            </ul>
          </div>
          <div className="footer__block-payments">
            <span>© 2023 card2card.kg</span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
