import React from "react";
import "./Guide.scss";

import man from "../../img/man.png";

function Guide() {
  return (
    <section id="how" className="guide">
      {/* <img className="ellipse" src={ellipse2} alt="Эллипс" /> */}
      <div className="container">
        <div className="guide__block">
          <div className="guide__block-img">
            <img src={man} alt="Мужчинга с телефоном и картой" />
            {/* <img className="dot" src={dot} alt="Точки" /> */}
          </div>
          <div className="guide__block-info">
            <h2 className="title__line">Как это работает?</h2>

            <ul>
              <li>
                <h2>Данные отправителя</h2>
                <p>
                  Заполните платежную форму, указав данные <br /> отправителя
                </p>
              </li>
              <li>
                <h2>Данные получателя</h2>
                <p>
                  Заполните платежную форму, указав данные <br /> получателя
                </p>
              </li>
              <li>
                <h2>Укажите сумму</h2>
                <p>Введите сумму, которую вы планируете перевести.</p>
              </li>
              <li>
                <h2>Укажите почту</h2>
                <p>
                  Укажите контактный адрес электронной <br /> почты отправки
                  сообщения о статусе перевода.
                </p>
              </li>
              <li>
                <h2>Подтверждение</h2>
                <p>
                  Подтвердите перевод при помощи кода из SMS <br />
                  отправленного вам на телефон
                </p>
              </li>
            </ul>
            {/* <img className="line" src={line} alt="Полоски" /> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Guide;
