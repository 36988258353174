import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import "./Header.scss";
import { Flex, Heading } from "@chakra-ui/react";

function Header() {
  return (
    <header>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <Heading size={"lg"}>
              <Flex alignItems={"center"}>
                CARD
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="22"
                  viewBox="0 0 34 22"
                  fill="none"
                >
                  <path
                    d="M33.2701 0.95166H12.2044H0.204407L19.5 6L0.204407 21.9517H33.2701L14 17L33.2701 0.95166Z"
                    fill="#51DEB1"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 17L33.2701 0.95166H12.2044H0.204407L19.5 6L0.204407 21.9517H33.2701L14 17Z"
                    fill="#51DEB1"
                  />
                </svg>
                CARD
              </Flex>
            </Heading>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav>
              <Nav.Link href="#how">Как это работает?</Nav.Link>
              <Nav.Link href="#advantages">Преимущества</Nav.Link>
              <Nav.Link href="#quest">Вопросы и ответы</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
