import React from "react";
import "./tarifs.scss";

const Tarifs = () => {
  return (
    <section id="tarifs" className="tarifs">
      <div className="container">
        <div className="tarifs__block">
          <div className="tarifs__block-info">
            <h2 className="title__line">Тарифы и ограничения</h2>
            <ul>
              <li>
                <h2>Комиссия за перевод – 5%</h2>
                <p>
                  от суммы, которую необходимо зачислить получателю <br /> (к
                  сумме к зачислению нужно добавить 5%)
                </p>
              </li>
              <li>
                <h2>Лимит</h2>
                <p>на 1 перевод 15 тыс. руб., суточный - 100 тыс. руб;</p>
              </li>

              <li>
                <h2>Ограничения</h2>
                <p>Переводы осуществляются только между картами МИР</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tarifs;
